import { useContext, useState } from 'react';

import OrderContext from '../components/OrderContext';

export default function useWreath() {
  // 1. Create some state to hold our order
  // replace with context
  // const [choice, setChoice] = useState([]);
  const [choice, setChoice] = useContext(OrderContext);

  // 2. Make a function add things to order
  function makeChoice(type, { name, price }) {
    setChoice({ ...choice, [type]: { name, price } });
  }

  // 4. Send this data the a serevrless function when they check out
  // TODO

  return {
    choice,
    makeChoice,
  };
}
