import { useState } from 'react';

export default function useSubmit(values, choice, terms, delivery) {
  const [error, setError] = useState();
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState('');

  async function submitOrder(e) {
    e.preventDefault();
    setLoading(true);
    setError(null);
    setMessage(null);
    const { name, email, phone, street, zip, city, nutella } = values;
    const body = {
      name,
      email,
      nutella,
      phone,
      street,
      zip,
      city,
      delivery,
      terms: terms ? 'yes' : '',
      size: choice.size.name,
      candles: choice.candle.name,
      style: choice.style.name,
      costs: `${
        (choice.size.price + choice.candle.price + choice.style.price) / 100
      } EUR`,
    };

    const res = await fetch(
      `${process.env.GATSBY_SERVERLESS_BASE}/placeOrder`,
      {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
      }
    );
    const text = JSON.parse(await res.text());

    // check if everything worked
    if (res.status >= 400 && res.status < 600) {
      setLoading(false);
      setError(text.message);
    } else {
      setLoading(false);
      setMessage('Vielen Dank! Ihr Kranz wird nun von uns angefertigt.');
    }
  }

  return {
    error,
    loading,
    message,
    submitOrder,
  };
}
