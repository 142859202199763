import { graphql, Link } from 'gatsby';
import React, { useState } from 'react';
import styled from 'styled-components';
import brush from '../assets/images/brushstroke-green-variant.png';
import SEO from '../components/SEO';
import useForm from '../utils/useForm';
import useSubmit from '../utils/useSubmit';
import useWreath from '../utils/useWreath';

const SimpleList = styled.ul`
  list-style-type: square;
  line-height: 1.5;
  margin-left: -1rem;
`;

const OrderStyles = styled.div`
  .nutella {
    display: none !important;
  }
  * {
    font-size: 1rem;
  }
  input[type='radio'] {
    margin-bottom: 0.5rem;
  }

  .configuration {
    [type='radio'] {
      position: absolute;
      opacity: 0;
      width: 0;
      height: 0;
    }
  }

  /* IMAGE STYLES */
  label {
    cursor: pointer;
  }

  legend {
    margin-top: 2rem;
    margin-bottom: 1rem;
  }

  fieldset {
    font-family: 'Open Sans';
    padding: 1rem;
  }

  h4 {
    font-size: 1.3rem;
    margin-bottom: 1.5rem;
    font-weight: bold;
  }

  .cart {
    display: none;
    font-family: 'Open Sans';
    @media (min-width: 700px) {
      position: fixed;
      background: rgba(255, 255, 255, 1);
      display: block;
      width: 200px;
      z-index: 99999;
      padding: 1.5rem;
      right: 0;
      border-top-left-radius: 8px;
      border-bottom-left-radius: 8px;
      box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.05);
    }
  }

  .choiceGrid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(130px, 1fr));
    gap: 1rem;
    margin-bottom: 4rem;
    &::after {
      content: '';
      flex: auto;
    }
    label {
      display: flex;
      flex-direction: column;
      text-align: center;
      align-items: center;
      padding: 1rem;
      span {
        margin-bottom: 0.5rem;
      }
    }
    .active {
      background-image: url(${brush});
      background-size: contain;
      background-repeat: no-repeat;
      background-position: center;
    }
  }

  .colorDot {
    display: inline-block;
    border-radius: 50%;
    width: 50px;
    height: 50px;
    border: 2px solid white;
    opacity: 1;
    box-shadow: 1px 1px 10px 4px rgba(0, 0, 0, 0.05);
  }

  .warning {
    color: white;
    border: 1px solid #eda87d;
    padding: 1rem;
    background: #eda87d;
  }

  input:invalid {
    border: 2px dashed #eda87d;
  }

  /* input:invalid:required {
    background-image: linear-gradient(to right, pink, lightgreen);
  } */

  input:valid {
    border: 2px solid var(--primary);
  }

  fieldset.address {
    label {
      display: grid;
      gap: 0.5rem;
      align-content: start;
    }
    label + label {
      margin-top: 1rem;
    }
  }
  fieldset.order {
    input {
      margin-right: 1rem;
    }
  }
`;

const ButtonStyles = styled.div`
  a {
    font-size: 150%;
    font-family: Drina;
  }
`;

export default function OrderPage({ data }) {
  const { sizes, candles, style } = data;
  const { values, updateValue } = useForm({
    nutella: '',
    name: '',
    email: '',
    phone: '',
    street: '',
    zip: '',
    city: '',
  });

  const { choice, makeChoice } = useWreath();

  const [terms, setTerms] = useState(false);
  const [delivery, setDelivery] = useState('');

  const { error, loading, message, submitOrder } = useSubmit(
    values,
    choice,
    terms,
    delivery
  );
  // TODO: Not working
  const isOrderable = () => {
    const isFilled = Object.values(values)
      .filter((item, i) => i !== 0)
      .every((item) => item !== '');
    const isChoicesMade = choice.candle && choice.size && choice.style;
    const isTerms = terms;
    const isDeliverySet = Boolean(delivery);
    return isFilled && isChoicesMade && isTerms && isDeliverySet;
  };

  const choiceSum = () =>
    Object.values(choice).reduce((total, item) => total + item.price, 0);

  function MySelection() {
    return (
      <div>
        <strong>Meine Auswahl: </strong> <br />
        <SimpleList>
          {choice.size && <li>Gesteckform: {choice.size?.name}</li>}
          {choice.candle && <li>Kerzen: {choice.candle?.name}</li>}
          {choice.style && <li>Dekoration: {choice.style?.name}</li>}
        </SimpleList>
        <p>
          <strong>Gesamtkosten: € {choiceSum() / 100} </strong>
        </p>
      </div>
    );
  }

  if (message)
    return (
      <>
        <div className="main">
          <h2>{message}</h2>
        </div>
      </>
    );

  return (
    <>
      <SEO title="Bestellung" />
      <div className="main">
       <h3>
          Leider können wir für dieses Jahr keine Bestellungen mehr
          entgegennehmen.
        </h3>
  
     
     {/*   <OrderStyles>
          {Object.values(choice).length > 0 && (
            <aside className="cart">
              <MySelection />
            </aside>
          )}
          <form>
            <fieldset disabled={loading} className="configuration">
              <legend>Kranz-Konfiguration</legend>
              <h4>Gesteckform wählen:</h4>
              <div className="choiceGrid">
                {sizes.nodes
                  .sort((a, b) => (a.sorting > b.sorting ? 1 : -1))
                  .map((size, i) => (
                    <React.Fragment key={size.id}>
                      <div
                        className={
                          choice?.size?.name === size.name ? 'active' : ''
                        }
                      >
                        <label htmlFor={size.id}>
                          <span>
                            <strong>{size.name}</strong>
                            <br />€{size.price / 100}
                          </span>
                          <input
                            id={size.id}
                            type="radio"
                            name="size"
                            value={size.name}
                            onChange={() => makeChoice('size', size)}
                          />
                          <img
                            src={size.image.asset.fixed.src}
                            alt={size.name}
                            width={i === 1 ? '80' : '100'}
                          />
                        </label>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
              <h4>Kerzen/Farbe wählen:</h4>
              <div className="choiceGrid">
                {candles.nodes
                  .sort((a, b) => (a.sorting > b.sorting ? 1 : -1))
                  .map((candle, i) => (
                    <React.Fragment key={candle.id}>
                      <div
                        className={
                          choice?.candle?.name === candle.name ? 'active' : ''
                        }
                      >
                        <label htmlFor={candle.id}>
                          <span>
                            <strong>{candle.name}</strong>
                          </span>
                          <input
                            id={candle.id}
                            type="radio"
                            name="candle"
                            value={candle.name}
                            onChange={() => makeChoice('candle', candle)}
                          />
                          <aside
                            className="colorDot"
                            style={{ background: candle.color?.hex }}
                          />
                        </label>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
              <h4>Dekoration wählen:</h4>
              <p>
                Unsere Kränze sind bereits mit Naturmaterialien dekoriert.
                Zusätzliche Optionen:
              </p>
              <div className="choiceGrid">
                {style.nodes
                  .sort((a, b) => (a.sorting > b.sorting ? 1 : -1))
                  .map((styleType, i) => (
                    <React.Fragment key={styleType.id}>
                      <div
                        className={
                          choice?.style?.name === styleType.name ? 'active' : ''
                        }
                      >
                        <label htmlFor={styleType.id}>
                          <span>
                            <strong>{styleType.name}</strong>
                            <br />€{styleType.price / 100}
                          </span>
                          <input
                            id={styleType.id}
                            type="radio"
                            name="style"
                            value={styleType.name}
                            onChange={() => makeChoice('style', styleType)}
                          />
                          {styleType.image ? (
                            <img
                              src={styleType.image?.asset.fixed.src}
                              alt={styleType.name}
                              width={i === 1 ? '80' : '120'}
                            />
                          ) : (
                            <div />
                          )}
                        </label>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </fieldset>

            <fieldset disabled={loading} className="address">
              <legend>Informationen</legend>
              <label htmlFor="name">
                Name
                <input
                  required
                  type="text"
                  id="name"
                  name="name"
                  value={values.name}
                  onChange={updateValue}
                />
              </label>
              <label htmlFor="email">
                E-Mail
                <input
                  required
                  type="email"
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={updateValue}
                />
              </label>
              <label className="nutella" htmlFor="nutella">
                <input
                  className="nutella"
                  type="text"
                  id="nutella"
                  name="nutella"
                  value={values.nutella}
                  onChange={updateValue}
                />
              </label>
              <label htmlFor="phone">
                Telefon
                <input
                  required
                  type="text"
                  id="phone"
                  name="phone"
                  value={values.phone}
                  onChange={updateValue}
                />
              </label>
              <label htmlFor="street">
                Straße, Hausnr.
                <input
                  required
                  type="text"
                  name="street"
                  id="street"
                  value={values.street}
                  onChange={updateValue}
                />
              </label>
              <label htmlFor="zip">
                PLZ
                <input
                  required
                  type="number"
                  id="number"
                  name="zip"
                  value={values.zip}
                  onChange={updateValue}
                />
              </label>
              <label htmlFor="city">
                Ort
                <input
                  required
                  type="text"
                  name="city"
                  id="city"
                  value={values.city}
                  onChange={updateValue}
                />
              </label>
            </fieldset>

            <fieldset disabled={loading} className="order">
              <legend>Bestellen</legend>
              <div>
                <label htmlFor="abholung">
                  <input
                    type="radio"
                    required
                    id="abholung"
                    name="deliveryOptions"
                    value="abholung"
                    onChange={(e) => setDelivery('Abholung')}
                  />
                  Abholung und Bezahlung (1. Dezember zwischen 16 und 18 Uhr, Erlenweg 8)
                </label>
                
              </div>
              <br />
              <div>
                <label htmlFor="terms">
                  <input
                    required
                    type="checkbox"
                    id="terms"
                    name="terms"
                    value="terms"
                    onChange={(e) => setTerms(!terms)}
                  />
                  Ich habe die Datenschutzbestimmungen gelesen und bin damit
                  einverstanden.
                </label>
              </div>
              <br />
              {isOrderable() ? (
                <>
                  <MySelection />
                  <ButtonStyles>
                    {!loading ? (
                      <Link
                        to="/bestellung"
                        className="mark"
                        onClick={submitOrder}
                      >
                        Jetzt bestellen
                      </Link>
                    ) : (
                      'Bestellung wird verarbeitet...'
                    )}
                  </ButtonStyles>
                </>
              ) : (
                <div>
                  <MySelection />
                  <br />
                  <div className="warning">
                    Um die Bestellung abzuschließen, füllen Sie bitte alle
                    Felder aus.
                  </div>
                </div>
              )}
              <br />
              {error ? (
                <div className="warning">Etwas ging schief: {error}</div>
              ) : (
                ''
              )}
              <br />
            </fieldset>
          </form>
        </OrderStyles>  */}
      </div>
    </>
  );
}



export const query = graphql`
  query MyQuery {
    sizes: allSanitySize {
      nodes {
        id
        sorting
        name
        price
        image {
          asset {
            fixed(width: 600, height: 200) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
    candles: allSanityCandles {
      nodes {
        id
        sorting
        name
        price
        color {
          hex
        }
      }
    }
    style: allSanityStyle {
      nodes {
        id
        sorting
        name
        price
        image {
          asset {
            fixed(width: 600, height: 200) {
              ...GatsbySanityImageFixed
            }
            fluid(maxWidth: 400) {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  }
`;
